import React from 'react';
import RealtimeChart from '../../charts/RealtimeChart';

function DashboardCard05({ logs, unit }) {

  return (
    <div className="flex flex-col col-span-full  bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100 flex items-center">
        <h2 className="font-semibold text-gray-800">Occurences</h2>
      </header>
      {/* Chart built with Chart.js 3 */}
      {/* Change the height attribute to adjust the chart height */}
      <RealtimeChart data={logs} width={595} height={248} unit={unit} />
    </div>
  );
}

export default DashboardCard05;
