import React, { useState } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

function Login() {

  const [code, setCode] = useState('');

  const login = () => {
    if (!code) {
      return message.error('Please enter the password!');
    }

    message.loading('Loading...', 1);

    Axios({
      method: 'post',
      url: '/auth2/login',
      data: {
        token: code
      },
    })
      .then(({ data }) => {
        const {
          authToken,
        } = data;

        window.location.href = `/?id=${authToken}`;
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            message.error('Nope!', 5);
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-xl mx-auto mt-10">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">CONNECT TO YOUR DASHBOARD</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        <div className="mb-2 mt-6">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
            ENTER THE PASSWORD *
          </label>
          <input
            onChange={(e) => setCode(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-lg"
            id="name"
            type="text"
            placeholder=""
            autoFocus={true}
            value={code}
          />
        </div>

        <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
          <button
            disabled={!code}
            onClick={login}
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${(!code) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">CONNECT</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default Login;
