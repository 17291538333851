import React, { useState, useEffect } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import TextBanner from '../partials/dashboard/TextBanner';
import AffiliatesTable from '../partials/AffiliatesTable';

function Affiliates(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [affiliates, setAffiliates] = useState([]);
  const [range, setRange] = useState('all');
  const [dates, setDates] = useState({ dateBegin: null, dateEnd: null });

  const loadAffiliates = (showLoading) => {
    let data = '';

    if (dates.dateBegin && dates.dateEnd) {
      data = `?dateBegin=${dates.dateBegin}&dateEnd=${dates.dateEnd}`;
    }

    if (showLoading) {
      message.loading('Loading', 1);
    }

    Axios({
      method: 'get',
      url: `/auth2/affiliates${data}`,
      headers: { 'x-auth': props.authId },
    })
      .then(({ data }) => {
        const {
          affiliates,
        } = data;

        setAffiliates(affiliates);
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  useEffect(() => {
    loadAffiliates();
  }, [dates]);

  const setDateInterval = (dateBegin, dateEnd) => {
    setDates({ dateBegin, dateEnd });
  }

  useEffect(() => {
    if (range === 'all') {
      setDateInterval(null, null);
    } else if (range === 'month') {
      setDateInterval(Date.now() - 30 * 24 * 60 * 60 * 1000, Date.now());
    } else if (range === 'week') {
      setDateInterval(Date.now() - 7 * 24 * 60 * 60 * 1000, Date.now());
    } else if (range === 'day') {
      setDateInterval(Date.now() - 24 * 60 * 60 * 1000, Date.now());
    }
  }, [range]);


  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={{ fullname: 'AMY OF THE ASSEMBLY', email: 'amy@assembly.co' }} notifications={[]} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <TextBanner title={'Affiliates. 🧑‍💻'} />

            <div className="mt-4">

              {/* Dashboard actions */}
              <div className="sm:flex sm:justify-end sm:items-center mb-8 space-x-2">
                <button
                  className={`${range === 'all' ? 'ring-2 ring-indigo-500' : ''} bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`}
                  onClick={() => setRange('all')}
                >All Time</button>
                <button
                  onClick={() => setRange('month')}
                  className={`${range === 'month' ? 'ring-2 ring-indigo-500' : ''} bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`}
                >This Month</button>
                <button
                  onClick={() => setRange('week')}
                  className={`${range === 'week' ? 'ring-2 ring-indigo-500' : ''} bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`}
                >This Week</button>
                <button
                  onClick={() => setRange('day')}
                  className={`${range === 'day' ? 'ring-2 ring-indigo-500' : ''} bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`}
                >Today</button>
              </div>

              <AffiliatesTable
                affiliates={affiliates}
              />
            </div>

          </div>
        </main>

      </div>
    </div>
  );
}

export default Affiliates;