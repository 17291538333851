import React, { useState } from 'react';

function ProcessWithdrawal({ data, approve, reject, withdrawalId }) {

  const [code, setCode] = useState('');
  const [reason, setReason] = useState('');

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">Process Withdrawal</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        <div className="mb-6 text-left">
          <p>Name: <span className="font-semibold">{data.name} ({data.active ? 'ACTIVE' : 'BLOCKED'})</span></p>
          <p>Email: <span className="font-semibold">{data.email}</span></p>
          <p>Signed on: <span className="font-semibold">{new Date(data.signedOn).toLocaleString()}</span></p>
          <p>Withdrawn: <span className="font-semibold">${data.withdrawn}</span></p>
          <p>Balance: <span className="font-semibold">${data.balance}</span></p>
          <p>Status: <span className="font-semibold">{data.status}</span></p>
          <p>Requested on: <span className="font-semibold">{new Date(data.requestedOn).toLocaleString()}</span></p>
          <p>Amount: <span className="font-semibold">${data.amount}</span></p>
          <p>Withdrawal Method: <span className="font-semibold">{data.withdrawalMethod} to {data.withdrawalName}</span></p>
          <p>Receiver: <span className="font-semibold text-lg"> {data.withdrawalDetails} </span></p>
        </div>

        <div className="mb-2">
          <label className="block text-gray-800 mb-1 text-sm font-bold">
            Reason if rejected
          </label>
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-base"
            id="name"
            type="text"
            placeholder="Insufficient Funds | Invalid Withdrawal Method details"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        <div className="mb-2 mt-6">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
            Two Factor Authentication Code *
          </label>
          <input
            onChange={(e) => setCode(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-lg"
            id="name"
            type="text"
            placeholder=""
            autoFocus={true}
            value={code}
          />
        </div>

        <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
          <button
            onClick={() => approve(withdrawalId, code)}
            disabled={data.status !== 'requested' || !code}
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${(data.status !== 'requested' || !code) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">Approve</span>
          </button>
          <button
            disabled={!code || !reason}
            onClick={() => reject(withdrawalId, reason, code)}
            className={`btn bg-red-500 hover:bg-red-600 text-white h-11 ${(!code || !reason) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">REJECT</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default ProcessWithdrawal;
