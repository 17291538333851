import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useLocation
} from 'react-router-dom';

import './css/style.scss';
import 'antd/dist/antd.css';

import { focusHandling } from 'cruip-js-toolkit';
import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import Login from './partials/Login';
import Withdrawals from './pages/Withdrawals';
import Affiliates from './pages/Affiliates';

import CONSTANTS from './constants';
import Axios from './api/axios';
import { message } from 'antd';
import Withdrawal from './pages/Withdrawal';
import ProductLogs from './pages/ProductLogs';
import TextLogs from './pages/TextLogs';
import SingleLog from './pages/SingleLog';
import Refund from './pages/Refund';

function App() {

  const location = useLocation();

  const [logs, setLogs] = useState([]);
  const [summary, setSummary] = useState([]);
  const [authId, setAuthId] = useState(null);
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let id = new URLSearchParams(window.location.search).get('id');
    if (id) {
      window.localStorage.setItem('id', id);
      window.location.href = '/';
    }

    id = window.localStorage.getItem('id');

    if (id) {

      Axios({
        method: 'get',
        url: '/logs/all',
        headers: { 'x-auth': id }
      })
        .then(({ data }) => {
          const { logs, summary } = data;

          setLoading(false);
          setAuthId(id);
          setLogs(logs);
          setSummary(summary);
          setConnected(true);
        })
        .catch((e) => {

          // console.log(e);

          if (e.response) {
            const { status } = e.response;

            if (status === 401) {
              setConnected(false);
            } else {
              message.error("Couldn't fetch the requested data!");
            }
          } else {
            message.error("Couldn't fetch the requested data!");
          }

        });
    } else {
      window.localStorage.removeItem('id');
      setConnected(false);
    }
  }, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change


  const logout = () => {
    window.localStorage.removeItem('id');
    window.location.href = `${CONSTANTS.WEBSITE_URL}`;
  }

  return (
    <>{connected && (
      <Switch>
        <Route exact path="/">
          <Dashboard authId={authId} logs={logs} summary={summary} logout={logout} />
        </Route>
        <Route path="/product">
          <ProductLogs authId={authId} logout={logout}  me={{ fullname: 'AMY OF THE ASSEMBLY', email: 'amy@assembly.co' }} notifications={[]} />
        </Route>
        <Route  path="/logs">
          <TextLogs authId={authId} logout={logout}  me={{ fullname: 'AMY OF THE ASSEMBLY', email: 'amy@assembly.co' }} notifications={[]} />
        </Route>
        <Route  path="/log">
          <SingleLog authId={authId} logout={logout}  me={{ fullname: 'AMY OF THE ASSEMBLY', email: 'amy@assembly.co' }} notifications={[]} />
        </Route>
        <Route exact path="/affiliates">
          <Affiliates authId={authId} logout={logout} />
        </Route>
        <Route exact path="/withdrawals">
          <Withdrawals authId={authId} logout={logout} />
        </Route>
        <Route path="/withdrawal" authId={authId} logout={logout}>
          <Withdrawal authId={authId} logout={logout} me={{ fullname: 'AMY OF THE ASSEMBLY', email: 'amy@assembly.co' }} notifications={[]} />
        </Route>
        <Route exact path="/refund">
          <Refund authId={authId} logout={logout} />
        </Route>
      </Switch>
    )}
      {
        loading && <p className="text-lg font-semibold text-gray-700">Loading..</p>
      }
      {
        !connected && !loading && <Login />
      }
    </>
  );
}

export default App;
