import React from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBDataTable } from 'mdbreact';

const WithdrawalsTable = ({ withdrawals }) => {
  const data = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
      },
      {
        label: 'Requested',
        field: 'requested',
        sort: 'asc',

      },
      {
        label: 'Status',
        field: 'status',
        sort: 'asc',

      },
      {
        label: 'Date',
        field: 'date',
        sort: 'asc',
      },
    ],
    rows: withdrawals.map((w) => ({ ...w, requested: `$${w.requested}`, balance: `$${w.balance}`, date: new Date(w.date).toLocaleString() })),
  };

  return (
    <div>
      <MDBDataTable
        onSearch={(value) => {
          if (value.length === 25 && value.split('').reverse()[0] === 'p') {
            window.location.href = `/withdrawal/${value}`;
          }
        }}
        striped
        bordered
        hover
        small
        noRecordsFoundLabel="No data available"
        responsive
        displayEntries={false}
        data={data}
        entries={20}
        noBottomColumns
        btn
        className="text-gray-700 font-medium"
        children={<p>j</p>}
      />
    </div>
  );
}

export default WithdrawalsTable;