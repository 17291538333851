import React from 'react';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { MDBDataTable } from 'mdbreact';

const AffiliatesTable = ({ affiliates }) => {
  const data = {
    columns: [
      {
        label: 'ID',
        field: 'id',
        sort: 'asc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'asc',
      },
      {
        label: 'Email',
        field: 'email',
        sort: 'asc',

      },
      {
        label: 'Referrals',
        field: 'referrals',
        sort: 'asc',

      },
      {
        label: 'Revenue',
        field: 'revenue',
        sort: 'asc',

      },
      {
        label: 'Withdrawn',
        field: 'withdrawn',
        sort: 'asc',
      },
      {
        label: 'Joined on',
        field: 'date',
        sort: 'asc',
      },
    ],
    rows: affiliates.map((af) => ({ ...af, revenue: `$${af.revenue}`, withdrawn: `$${af.withdrawn}`, date: new Date(af.date).toLocaleString() })),
  };

  return (
    <div>
      <MDBDataTable
        striped
        bordered
        hover
        small
        exportToCSV
        noRecordsFoundLabel="No data available"
        responsive
        displayEntries={false}
        data={data}
        entries={20}
        noBottomColumns
        className="text-gray-700 font-medium"
      />
    </div>
  );
}

export default AffiliatesTable;