import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import Axios from '../api/axios';
import CONSTANTS from '../constants';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import TextBanner from '../partials/dashboard/TextBanner';
import Logs from '../partials/dashboard/Logs';


function TextLogs(props) {

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [product, setProduct] = useState('');
  const [text, setText] = useState('');
  const [logs, setLogs] = useState([]);

  const loadTextLogs = () => {
    if (!product || !text) return;

    Axios({
      method: 'get',
      url: `/logs/filter/?product=${product}&text=${text}`,
      headers: { 'x-auth': props.authId },
    })
      .then(({ data }) => {
        setLogs(data.logs);
      })
      .catch(({ response }) => {

        if (response) {
          const { status } = response;

          if (status === 401) {
            window.localStorage.removeItem('id');
            window.location.href = `${CONSTANTS.WEBSITE_URL}`;
          } else if (status === 400) {
            message.error(response.data.errors[0].msg, 5);
          } else if (status === 429) {
            message.error('Please slow down! Wait 2 minutes and try again', 5);
          } else {
            message.error(response.data.message, 5);
          }
        } else {
          message.error('Network error!', 5);
        }

      });
  }

  useEffect(() => {
    setProduct(window.location.pathname.split('/')[2]?.slice(0, 24));
    setText(window.location.pathname.split('/')[3]);
  }, []);

  useEffect(() => {
    if (product && text) {
      loadTextLogs();
    }
  }, [product, text]);

  return (
    <div className="flex h-screen overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header me={props.me} notifications={props.notifications} sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} logout={props.logout} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

            {/* Welcome banner */}
            <TextBanner title={`${decodeURI(text)} on ${product.toUpperCase()}. 🧭️`} />

            {/* Dashboard actions */}
            <div className="sm:flex sm:justify-end sm:items-center mb-8">

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
              </div>

            </div>

            <div className="mt-4">
              <Logs logs={logs} product={product} />
            </div>

          </div>
        </main>

      </div>
    </div>
  );
}

export default TextLogs;