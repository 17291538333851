import React, { useState } from 'react';

function ProcessRefund({ refund }) {

  const [code, setCode] = useState('');
  const [rcode, setRcode] = useState('');
  const [amount, setAmount] = useState('');

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">Process Refund</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        <div className="mb-2">
          <label className="block text-gray-800 mb-1 text-sm font-bold">
            RCODE
          </label>
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-base"
            type="text"
            placeholder="user rcode"
            value={rcode}
            onChange={(e) => setRcode(e.target.value)}
          />
        </div>

        <div className="mb-2">
          <label className="block text-gray-800 mb-1 text-sm font-bold">
            AMOUNT
          </label>
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-base"
            type="number"
            placeholder="amount to refund"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>

        <div className="mb-2 mt-6">
          <label className="block text-gray-500 text-base font-medium mb-1" htmlFor="name">
            Two Factor Authentication Code *
          </label>
          <input
            onChange={(e) => setCode(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-500 font-medium rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-lg"
            id="name"
            type="text"
            placeholder=""
            autoFocus={true}
            value={code}
          />
        </div>

        <div className="flex lg:space-x-3 pt-3 bg-white mt-2 justify-end">
          <button
            onClick={() => refund(rcode, amount, code)}
            disabled={!amount || !code}
            className={`btn bg-indigo-500 hover:bg-indigo-600 text-white h-11 ${(!amount || !code) ? 'cursor-not-allowed' : ''}`}
          >
            <span className="block">Refund</span>
          </button>
        </div>

      </div>
    </div>
  );
}

export default ProcessRefund;
