import React from 'react';
import { Link } from 'react-router-dom';

function LatestLogs({ logs, product }) {
  return (
    <div className="col-span-full bg-white shadow-lg rounded-sm border border-gray-200">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-semibold text-gray-800">Latest Logs on {product.toUpperCase()}</h2>
      </header>
      <div className="p-3">

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs uppercase text-gray-400 bg-gray-50 rounded-sm">
              <tr>
                <th className="p-2">
                  <div className="font-semibold text-left">Text</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Date</div>
                </th>
                <th className="p-2">
                  <div className="font-semibold text-center">Type</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm font-medium divide-y divide-gray-100">
              {
                logs.map((l, i) => (
                  <tr key={i}>
                  <td className="p-2">
                    <div className="flex items-center">
                      <div className="text-gray-800"><Link to={`/logs/${product}/${l.text}`}>{l.text}</Link></div>
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="text-center">{new Date(l.date).toLocaleString()}</div>
                  </td>
                  <td className="p-2">
                    <div className={`text-center ${l.type === 'error' ? 'text-red-500' : 'text-green-500'}`}>{l.type}</div>
                  </td>
                </tr>
                ))
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}

export default LatestLogs;
