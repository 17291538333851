import React from 'react';

function SingleLogDetails({ log }) {

  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-gray-200 max-w-xl mx-auto">
      <header className="px-5 py-4 border-b border-gray-100">
        <h2 className="font-medium text-gray-800 text-base">Details</h2>
      </header>
      <div className="mb-2 px-8 pb-2">

        {log && <div className="mb-6 text-left">
          <p>Text: <span className="font-semibold">{log.text}</span></p>
          <p>Type: <span className="font-semibold">{log.type}</span></p>
          <p>Time: <span className="font-semibold">{new Date(log.createdAt).toLocaleString()}</span></p>
          <p>Product: <span className="font-semibold">{log.product.toUpperCase()}</span></p>
          <br />
          <p>Additional:</p>
          {
            log.data.map(([k, v]) => (
              <p>+{k}: <span className="font-semibold">{v}</span></p>
            ))
          }
        </div>}

      </div>
    </div>
  );
}

export default SingleLogDetails;
